import { defineComponent } from 'vue';
import BaseButton from '../atoms/BaseButton.vue';
import VideoComponent from '../atoms/VideoComponent.vue';
export default defineComponent({
    name: 'HeaderComponent',
    components: { BaseButton, VideoComponent },
    props: {
        dark: { type: Boolean, required: false },
        contentTop: { type: Boolean, required: false },
        lightText: { type: Boolean, required: false },
    },
});
