import { defineComponent, ref } from 'vue';
import BaseIcon from './BaseIcon.vue';
import playImage from '../../assets/media/Play.png';
export default defineComponent({
    name: 'VideoComponent',
    components: { BaseIcon },
    setup() {
        const showVideo = ref(false);
        const showVideoHandler = () => (showVideo.value = true);
        const closeVideoHandler = () => (showVideo.value = false);
        return {
            showVideo,
            showVideoHandler,
            closeVideoHandler,
            playImage,
        };
    },
});
